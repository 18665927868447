import { useEffect, useRef } from 'react';

import { type DtoPage } from '@lp-lib/api-service-client/public';
import { type Media, MediaFormatVersion, MediaType } from '@lp-lib/media';

import { fromMediaDTO } from '../../../src/utils/api-dto';
import {
  ImagePickPriorityLowToHigh,
  MediaUtils,
} from '../../../src/utils/media';

function BackgroundImage(props: { media: Media }) {
  const { media } = props;

  const thumbnail =
    MediaUtils.PickMediaUrl(media, {
      priority: ImagePickPriorityLowToHigh,
    }) || '';
  const raw =
    MediaUtils.PickMediaUrl(media, {
      priority: [MediaFormatVersion.Raw],
    }) || '';

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (raw === thumbnail) return;

    const imgRaw = new Image();
    imgRaw.src = raw;
    imgRaw.onload = () => {
      if (!imgRef.current) return;

      imgRef.current.src = raw;
      imgRef.current.classList.remove('filter', 'blur');
    };
  }, [raw, thumbnail]);

  if (!thumbnail || !raw) return null;
  return (
    <img
      ref={imgRef}
      src={thumbnail}
      className={`w-full ${raw !== thumbnail ? 'filter blur' : ''}`}
      alt=''
    />
  );
}

function BackgroundVideo(props: { media: Media }) {
  const { media } = props;

  const poster = MediaUtils.PickMediaUrl(media, {
    videoThumbnail: 'first',
  });
  const url = MediaUtils.PickMediaUrl(media);
  if (!url) return null;
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      src={url}
      poster={poster || undefined}
      className='w-full'
    />
  );
}

export function PublicLibraryBackground(props: { page: DtoPage }) {
  const { page } = props;

  const media = fromMediaDTO(page.background?.media);
  if (!media) return null;

  return (
    <div className='absolute w-full page-background-mask-image opacity-60'>
      {media.type === MediaType.Video ? (
        <BackgroundVideo media={media} />
      ) : (
        <BackgroundImage media={media} />
      )}
    </div>
  );
}
